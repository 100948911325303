/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 10th 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 22nd 2022, 2:16:35 am
 * ---------------------------------------------
 */

.button {
  @include font-size(22px);
  font-family: $content-bold-font;
  text-transform: uppercase;

  padding: 18px 24px;
  display: block;
  border: 4px solid #0C1E32;
  border-radius: 6px;
}

.icon {
  display: block;

  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
  }

  &.fb:before {
    background: url('/wp-content/themes/zincwell/assets/images/icons/fb.png') center/contain no-repeat;
  }

  &.ig:before {
    background: url('/wp-content/themes/zincwell/assets/images/icons/ig.png') center/contain no-repeat;
  }

  @include on(820, down) {
    &:before {
      width: 28px;
      height: 28px;
    }
  }
}

.socials {
  >.link {
    +.link {
      margin-left: 24px;
    }
  }
}

.available-at {
  >.title {
    @include font-size(24px);
    font-family: $content-bold-font;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    line-height: 1.2;

    margin-bottom: 16px;
  }

  >.logo-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 32px;

    >a {
      display: block;
      margin: 16px;

      >.logo {
        max-width: 220px;
        width: 100%;
      }

      &:nth-of-type(2)>.logo {
        max-width: 320px;
        width: 100%;
      }

      &:last-of-type>.logo {
        max-width: 180px;
        width: 100%;
      }
    }
  }

  @include on(820, down) {
    padding: 80px 0;

    >.title {
      margin-bottom: 32px;
    }

    >.logo-container {
      flex-direction: column;
      margin-bottom: 0;
      gap: 48px;

      >a {
        margin: 0;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.message-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  >.contact-here {
    @include font-size(32px);
    font-family: $content-bold-font;
    position: relative;
    padding-left: 36px;
    line-height: 1.2;

    >a {
      color: #fff;
    }

    &:before {
      content: "";
      width: 24px;
      height: 24px;
      display: block;

      background: url('/wp-content/themes/zincwell/assets/images/zincwell-plus-invert.png') center/contain no-repeat;

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  @include on(820, down) {
    flex-direction: column;
    justify-content: center;

    >.contact-here {
      @include font-size(28px);

      width: 100%;
      max-width: 340px;
      margin-bottom: 32px;
      padding-left: 48px;
    }
  }

  @include on(426, down) {
    >.contact-here {
      @include font-size(24px);
    }
  }
}


.message-us {
  background: #11BDC6;
  padding: 8vh 0;

  @include on(820, down) {
    padding: 48px 0;
  }
}


.contact-info {
  color: #fff;

  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 48px;


  >.zincwell {
    >.img {
      max-width: 200px;
    }
  }

  >.address {
    line-height: 1.4;
    display: flex;
    max-width: 420px;

    &:before {
      font-family: $awesome-font;
      font-size: 18px;

      content: "\f1ad";
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 12px;
    }
  }

  >.contact {
    >.link {
      display: flex;
      line-height: 1.4;

      &:before {
        font-family: $awesome-font;
        font-size: 18px;

        content: "";
        width: 20px;
        height: 20px;
        display: block;
        margin-right: 12px;
      }

      &.number:before {
        content: "\f095";
      }

      &.email:before {
        content: "\f0e0";
      }

      +.link {
        margin-top: 12px;
      }
    }
  }

  >.socials {
    display: flex;
    max-width: 120px;
  }

  @include on(820, down) {
    margin: 48px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    >.zincwell {
      >.img {
        width: 200px;
      }
    }

    >.address {
      max-width: 100%;
      width: 380px;
    }

    >.contact {
      max-width: 100%;
      width: 380px;
    }
  }
}


.contact-container {
  display: flex;
  flex-direction: column;
}

.contact-section {
  max-width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}