/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Monday July 11th 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 21st 2022, 10:27:49 pm
 * ---------------------------------------------
 */


.menu-bar {
  position: relative;
  height: 60px;
  background: #0C1E32;
  z-index: 999;

  display: flex;
  justify-content: space-between;

  > .burger {
    width: 80px;
    background:  url(/wp-content/themes/zincwell/assets/images/burger.png) center / 28px no-repeat;
  }

  > .zinwell-icon {
    width: 80px;
    background: #11BDC6 url(/wp-content/themes/zincwell/assets/images/zcross.png) center / 28px no-repeat;
  }
}

.menu-main-menu-container {
  @include on(770, down) {
    display: none;
  }
}


.menu-wrapper {
  position: relative;

  >.menu-main-menu-container {
    position: fixed;
    background: #fff;
    border-radius: 0 0 10px 10px;
    z-index: 0;
    width: 100%;
    transition: all 0.4s ease-in-out;
    top: -100%;

    &.active {
      top: 0;
    }


    >.menu {
      margin-top: 60px;
      padding: 24px 32px;
      display: block;

      >.menu-item {
        padding: 16px 0;
        position: relative;

        &:last-of-type {
          padding-bottom: 0;
        }

        &:first-of-type {
          padding-top: 0;
        }

        &:after {
          top: auto;
          bottom: 0;
          right: 0;
          height: 2px;
          width: 100%;
          background: #11BDC6;
        }
      }
    }
  }
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  @include on(770, down) {
    display: block;

    >.menu-wrapper>.menu-main-menu-container {
      display: block;
    }
  }
}