/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 22nd 2022, 2:16:11 am
 * ---------------------------------------------
 */

.zincwell-abilities {
  >.title {
    @include font-size(48px);

    color: #11bdc6;
    font-family: $content-bold-font;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  >.abilities {
    max-width: 640px;

    >.item {
      position: relative;
      padding-left: 42px;

      >p {
        @include font-size(22px);
        line-height: 1.4;

        >strong {
          font-family: $content-bold-font;
          font-weight: 600;
        }
      }

      &:before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;

        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;

        background: url("/wp-content/themes/zincwell/assets/images/zincwell-plus.png") center/contain no-repeat;
      }

      +.item {
        margin-top: 24px;
      }
    }

    +.warriors {
      margin-top: 32px;
    }
  }

  >.warriors {
    >.img {
      max-width: 460px;
    }
  }

  @include on(500, down) {
    >.title {
      @include font-size(32px);
    }

    >.abilities {
      >.item {
        >p {
          @include font-size(16px);
        }
      }
    }

    >.warriors {
      >.img {
        max-width: 320px;
      }
    }
  }
}



.about-zincwell {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  >.product {
    text-align: center;

    >.tagline {
      >.img {
        max-width: 140px;
      }

      +.zincwell {
        margin-top: 24px;
      }
    }

    >.zincwell {
      position: relative;

      >.img {
        max-width: 150px;

        position: relative;
        left: -18px;
      }
    }

    +.zincwell-abilities {
      margin-left: 80px;
    }
  }

  @include on(820, down) {
    padding: 100px 0;
    align-items: flex-start;
    min-height: 0;

    >.product {
      display: none;

      +.zincwell-abilities {
        margin-left: 0;
      }

    }

    >.zincwell-abilities {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 32px;

      >.title {
        width: 100%;
        text-align: center;
        margin: 0;
      }

      >.mobile-display {
        display: block;

        >.zincwell {
          >.img {
            max-width: 120px;
          }
        }
      }

      >.abilities {
        max-width: calc(100% - 160px);

        +.warriors {
          margin: 0;
        }
      }
    }
  }

  @include on(500, down) {

    >.zincwell-abilities {
      >.mobile-display {

        >.zincwell {
          >.img {
            max-width: 100px;
          }
        }
      }

      >.abilities {
        max-width: calc(100% - 132px);
      }
    }
  }

  @include on(376, down) {
    >.zincwell-abilities {
      >.mobile-display {
        display: none;
      }

      >.abilities {
        max-width: 100%;
      }
    }
  }
}