/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Monday July 11th 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 11th 2022, 10:12:16 pm
 * ---------------------------------------------
 */


 .slick-dots {
    bottom: 0;
  }
  
  .slick-dotted.slick-slider {
    margin: 0;
  }
  
  
  .slick-dots {
    right: 0;
    bottom: 16px;
  
    >li {
      height: auto;
      width: auto;
      margin: 0 12px;
  
      >button {
        height: auto;
        width: auto;
        padding: 0;
  
        &:before {
          height: auto;
          width: auto;
          font-size: 30px;
          position: relative;
          color: #fff;
          opacity: 0.5;
        }
      }
  
      &.slick-active {
        >button {
          &:before {
            color: #6CFCFF;
            opacity: 0.8;
          }
        }
      }
    }
  }
  
  .slick-vertical {
    position: relative;
  
    >.slick-dots {
      display: flex;
      flex-direction: column;
      width: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 32px;
      margin: auto;
      justify-content: center;
  
      >li {
        margin: 12px 0;
      }
    }
  }