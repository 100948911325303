/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 21st 2022, 10:31:51 pm
 * ---------------------------------------------
 */

.menu-main-menu-container {
  >.menu {
    display: flex;

    >.menu-item {
      padding: 2px 16px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 4px;
        background-color: #0c1e32;
      }

      &:last-of-type:after {
        content: none;
      }

      >a {
        @include font-size(16px);

        text-transform: uppercase;
        font-weight: 600;
        line-height: 1.4;
      }


      &.active {
        >a {
          color: #11BDC6;
        }
      }

      @include on(770, up) {
        &:hover>a {
          color: #11BDC6;
        }
      }
    }
  }
}

.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
  padding: 18px 0;
  background: rgba(#FFF, 0.5);
  z-index: 10;
  box-shadow: 0 -2px 8px 1px rgba(#000, 0.4);

  >.menu-main-menu-container {
    max-width: 1024px;
    margin: auto;
  }

  @include on (1024, down) {
    >.menu-main-menu-container {
      padding: 0 32px;
    }
  }

  @include on (770, down) {
    display: none;
  }
}