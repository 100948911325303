/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 3rd 2022, 8:16:55 pm
 * ---------------------------------------------
 */

// FONTS
$content-font: "Sofia Pro", Arial, sans-serif;
$content-bold-font: "Sofia Pro Bold", "Sofia Pro", sans-serif;
$awesome-font: "FontAwesome";

// PRIMARY COLORS

// TEXT COLORS

// MISC

// VIEWPORT
$default-viewport: 1240px;
