/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 25th 2022, 10:34:40 pm
 * ---------------------------------------------
 */

.zinc-properties {
  line-height: 1.4;
  font-size: 1.2rem;

  >p {
    +p {
      margin-top: 24px;
    }

    >.large {
      font-family: $content-bold-font;
    }
  }
}

.effectiveness {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >.more {
    max-width: 380px;
    margin-top: 32px;
  }

  >.warriors {
    max-width: 240px;
    margin-top: 16px;
  }
}


.contianer {
  position: relative;

  >.top {
    text-align: center;

    >.img {
      height: 90px;
      width: auto;
    }

    &.nano {
      position: relative;
      z-index: 1;
    }

    @include on (1200, down) {
      >.img {
        height: 70px;
      }
    }
  }

  >.zinc-properties {
    max-width: 440px;
    padding: 0 0 0 32px;
    margin: 32px auto 0 auto;
    position: relative;
    right: -64px;


    @include on (1300, down) {
      right: 0;
      max-width: none;
    }
  }

  >.shield {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: auto;
    max-width: 100%;
    z-index: 0;

    @include on(1260, down) {
      left: -999px;
      right: -999px;
      max-width: 100%;
      width: auto;
    }
  }
}

.zinc-nano {
  min-height: 100vh;
  display: flex;
  padding: 0 60px;
  overflow: hidden;

  >.portion {
    width: calc(50% - 90px);
    padding: 20vh 0 0;

    &.cross {
      width: 180px;
    }

    &.nano {
      overflow: hidden;
    }

    >.contianer {
      height: calc(100% - 24vh);

      @media all and (min-height: 900px) {
        height: 460px;
      }
    }
  }


  @include on (1200, down) {
    padding: 0;

    >.portion {
      width: calc(50% - 60px);

      &.cross {
        width: 120px;
      }
    }
  }


  @include on (820, down) {
    min-height: 0;
    display: block;

    >.portion {
      display: none;
    }

    >.mobile-display {
      display: block;
      padding: 100px 0;

      >.zinc-plus-nano {
        text-align: center;
        margin-bottom: 32px;

        >.img {
          max-width: 100%;
          width: 420px;
        }
      }

      >.zinc-properties {
        p {
          text-align: center;
        }
      }
    }
  }
}