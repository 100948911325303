/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 22nd 2022, 2:17:17 am
 * ---------------------------------------------
 */

.home-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;

  >.title {
    text-align: center;

    >.zincwell {
      >.img {
        max-width: 660px;
      }

      +.warriors {
        margin-top: 24px;
      }
    }

    >.warriors {
      >.img {
        max-width: 500px;
      }
    }

    +.product {
      margin-left: 80px;
    }
  }

  >.product {
    text-align: center;

    >.zincwell {
      position: relative;

      >.img {
        max-width: 160px;

        position: relative;
        left: -18px;
      }

      +.tagline {
        margin-top: 16px;
      }
    }

    >.tagline {
      >.img {
        max-width: 180px;
      }
    }
  }


  @include on(820, down) {
    display: block;

    >.title,
    >.product {
      display: none;
    }

    >.mobile-display {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      padding: 100px 0;

      .introduction,
      .warriors {
        >.img {
          max-width: 400px;
        }
      }

      .zincwell {
        >.img {
          max-width: 240px;
        }

        +.tagline {
          margin-top: 32px;
        }
      }

      .tagline {
        >.img {
          max-width: 160px;
        }
      }

      .product {
        >.img {
          max-width: 160px;
        }
      }

      .title {
        text-align: center;
      }

      >.title-product {
        display: flex;
      }
    }
  }

  @include on(820, down) {
    min-height: 0;
  }
}


.main-page {
  .section.home {
    @include on(820, down) {
      background-size: cover;
    }
  }
}