/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 3rd 2022, 3:28:18 pm
 * ---------------------------------------------
 */

// --------------------------------------------------
// font size

@function calculate-rem($size) {
  $rem-size: $size / 16px;
  @return $rem-size * 1rem;
}

@mixin font-size($size) {
  font-size: calculate-rem($size);
}

@mixin on($breakpoint, $behavior: up) {
  @if $behavior==up {
    @media all and (min-width: #{$breakpoint}px) {
      @content;
    }
  } @else {
    @media all and (max-width: #{$breakpoint}px) {
      @content;
    }
  }
}
