/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 22nd 2022, 12:44:40 am
 * ---------------------------------------------
 */

.main-container {
  max-width: 1440px;
  margin: auto;
  padding: 0 42px;
}

.main-page {
  width: 100%;

  .section.home {
    background: #e6e6e6 url("/wp-content/themes/zincwell/assets/images/about-bg.png") center 0 / contain repeat-x;
  }

  .section.about {
    background: #b3e1e2;
  }

  .section.nano,
  .section.how {
    background: #e6e6e6;
  }

  .section.contact {
    background: #0C1E32;
  }
}

.whole-page {
  position: relative;
}
