/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Thursday July 7th 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 25th 2022, 9:54:30 pm
 * ---------------------------------------------
 */

.instructions {
  >.img {
    max-width: 380px;

    +.title {
      margin-top: 32px;
    }
  }

  >.title {
    padding-left: 32px;

    @include font-size(24px);
    font-family: $content-bold-font;
    font-weight: 600;
    text-transform: uppercase;
    max-width: calc(100% - 32px);
    width: calc(420px - 32px);
    line-height: 1.2;

    +.inst {
      margin-top: 20px;
    }
  }

  >.inst {
    padding-left: 32px;

    @include font-size(20px);
    position: relative;
    max-width: calc(100% - 32px);
    width: calc(420px - 32px);
    line-height: 1.4;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      background: url('/wp-content/themes/zincwell/assets/images/zincwell-plus.png') center/cover no-repeat;

      position: absolute;
      left: 0;
      top: 6px;
      margin: auto;
    }

    +.inst {
      margin-top: 20px;
    }
  }
}


.how-to-section {
  display: flex;
  justify-content: flex-end;

  >.instructions {
    padding: 20vh 0 0;
    width: 40%;
  }
}

.single-slider {
  width: 100%;
  min-height: 100vh;

  >.desktop-slider {
    min-height: 100vh;
  }

  >.mobile-display {
    min-height: 100vh;

    .instructions {
      padding: 100px 0;

      >.img {
        max-width: 100%;
        width: 320px;
        margin: auto;
      }

      >.title {
        margin-left: auto;
        margin-right: auto;
      }

      >.inst {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @include on (820, down) {
    >.desktop-slider {
      display: none;
    }

    >.mobile-display {
      display: block;
    }

    @media all and (max-height: 900px) {
      min-height: 126vh;

      >.mobile-display {
        min-height: 126vh;
      }
    }
  }
}

.all-how-to {
  width: 100%;
  min-height: 100vh;

  @include on (820, down) {
    @media all and (max-height: 900px) {
      min-height: 126vh;
    }
  }
}