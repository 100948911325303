/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 21st 2022, 11:34:18 pm
 * ---------------------------------------------
 */



.img {
  max-width: 100%;
  width: 100%;
}

.mobile-display {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  background-color: #e6e6e6;
  color: #0c1e32;
  font-family: $content-font;
  // @include on(770, down){
  //   height: 100vh;
  //   overflow: auto;
  // }
}


html {
  overflow-y: auto;
  scroll-behavior: smooth;  
}