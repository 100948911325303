/*
 * ---------------------------------------------
 * Author: Chui Perez
 * Date: Sunday July 3rd 2022
 * Last Modified by: Chui Perez - <chui@healthnow.ph>
 * Last Modified time: July 3rd 2022, 8:16:28 pm
 * ---------------------------------------------
 */

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

@font-face {
  font-family: "Sofia Pro";
  src: url("/wp-content/themes/zincwell/assets/fonts/sofia_pro_regular.woff2")
      format("woff2"),
    url("/wp-content/themes/zincwell/assets/fonts/sofia_pro_regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro Bold";
  src: url("/wp-content/themes/zincwell/assets/fonts/sofia_pro_bold.woff2")
      format("woff2"),
    url("/wp-content/themes/zincwell/assets/fonts/sofia_pro_bold.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
